<script setup lang="ts">
const open = defineModel<boolean>("open")

withDefaults(defineProps<{ size?: "sm" | "lg"; gutter?: boolean }>(), {
  size: "sm",
  gutter: true,
})
const emit = defineEmits(["close"])

const showPrompt = ref(false)

watch(
  () => open.value,
  (newValue) => {
    if (!newValue) {
      emit("close")
    }
    setTimeout(() => {
      showPrompt.value = newValue ?? false
    }, 200)
  },
)

const close = () => {
  showPrompt.value = false
  emit("close")
  setTimeout(() => {
    open.value = false
  }, 200)
}

onMounted(() => {
  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape" && open.value) {
      close()
    }
  })
})
</script>

<template>
  <DialogWrapper v-model:open="open">
    <dialog
      :open
      class="relative z-40"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
        <div
          :class="[
            { 'translate-x-0': showPrompt },
            { 'translate-x-full': !showPrompt },
            { 'max-w-[625px]': size == 'sm' },
            { 'max-w-6xl': size == 'lg' },
          ]"
          class="pointer-events-auto w-screen transform p-2 transition duration-200 ease-in-out sm:duration-300 md:p-4"
        >
          <div
            class="flex h-full flex-col overflow-hidden rounded-2xl bg-slate-100 shadow-xl"
          >
            <div
              class="flex items-center justify-between rounded-t-2xl px-4 py-4"
            >
              <div><slot name="title" /></div>
              <CloseButton @click="close" />
            </div>
            <div
              class="relative flex-1 overflow-y-auto"
              :class="{ 'px-4 pb-4': gutter }"
            >
              <slot />
            </div>
            <div
              v-if="$slots.bottom"
              class="sticky bottom-0 w-full border-t border-slate-200 bg-slate-100"
              :class="{ 'p-4': gutter }"
            >
              <slot name="bottom" />
            </div>
          </div>
        </div>
      </div>
    </dialog>
  </DialogWrapper>
</template>
